import React, { useEffect, useState, useRef } from 'react';

import blankPagePlaceholder from '../../Assets/blankPagePlaceholder.svg';
import blankPageArrow from '../../Assets/blankPageArrow.svg';

import textIcon from '../../Assets/elements/Text.svg';
import buttonIcon from '../../Assets/elements/Button.svg';
import imageIcon from '../../Assets/elements/Image.svg';
import videoIcon from '../../Assets/elements/Video.svg';
import containerIcon from '../../Assets/elements/Container.svg';
import timerIcon from '../../Assets/elements/Timer.svg';
import formIcon from '../../Assets/elements/Form.svg';
import stripeIcon from '../../Assets/elements/Stripe.svg';
import customIcon from '../../Assets/elements/Custom.svg';
import BookCover from '../../Assets/elements/BookCover.svg';

import { rxCreationData } from '../../rx/rxState';

import StyledSideMenu from './StyledSideMenu';
import Modal from '../Common/Modal/Modal';
import PrimaryButton from '../Common/PrimaryButton/PrimaryButton';
import clsx from 'clsx';
import { isAdmin } from '../../utils/api';

const kBlockAdder = [
  {
    name: 'Text',
    type: 'Text',
    options: {},
    icon: textIcon,
  },
  {
    name: 'Button',
    type: 'Button',
    options: {},
    icon: buttonIcon,
  },
  {
    name: 'Image',
    type: 'Image',
    options: {},
    icon: imageIcon,
  },
  {
    name: 'Book',
    type: 'BookCoverPreview',
    options: {},
    icon: BookCover,
  },
  {
    name: 'Video',
    type: 'Video',
    options: {},
    icon: videoIcon,
  },
  {
    name: 'Timer',
    type: 'Timer',
    options: {},
    icon: timerIcon,
  },
  {
    name: 'Form',
    type: 'Form',
    options: {},
    icon: formIcon,
  },
  {
    name: 'Stripe',
    type: 'Stripe',
    options: {},
    icon: stripeIcon,
  },
  {
    name: 'Container',
    type: 'Row',
    options: {},
    icon: containerIcon,
  },
  {
    name: 'Custom',
    type: 'Custom',
    options: {},
    icon: customIcon,
  },
];

const SideMenu = () => {
  const [isTutorialActive, setIsTutorialActive] = useState(false);

  const onDragStart = (event) => {
    const id = event.currentTarget.id;
    const data = kBlockAdder[id];
    if (data) {
      rxCreationData.next({
        data,
        blockType: data.type,
      });
    }
  };

  const onDragEnd = (event) => {
    if (event.dataTransfer.dropEffect === 'none') {
      //Drop got Canceled
      rxCreationData.next({
        blockType: null,
      });
    }
  };
  return (
    <>
      <StyledSideMenu>
        <div className={clsx('menu', { admin: isAdmin() })}>
          {kBlockAdder.map((block, i) => (
            <div
              key={i}
              id={i}
              className={'menu-item'}
              draggable="true"
              onDragStart={(e) => onDragStart(e)}
              onDragEnd={(e) => onDragEnd(e)}
            >
              <div className={'image'}>
                <img src={block.icon} alt="text" />
              </div>
              <div className={'label'}>{block.name}</div>
            </div>
          ))}
        </div>

        <Modal displayModal={isTutorialActive}>
          <img alt="" src={blankPageArrow} className={'blankPageArrow'} />
          <div className={'placeholderContainer'}>
            <img
              alt=""
              src={blankPagePlaceholder}
              className={'blankPagePlaceholder'}
            />
            <p className={'blankPageText'}>
              Would you like to to add more content? <br /> Just drag and drop
              sections from the left bar into your page!
            </p>
          </div>
          <div className={'blankPageButton'}>
            <PrimaryButton
              height={40}
              width={180}
              onClick={() => setIsTutorialActive(false)}
              name={'Ok, Got It'}
            />
          </div>
        </Modal>
      </StyledSideMenu>
    </>
  );
};

export default SideMenu;
