export const CREATE_PAYMENT_INTENT_3 = {
  operationName: 'createPaymentIntent3',
  query: `mutation createPaymentIntent3(
      $projectId: String!
      $productID: String!
      $priceID: String!
      $setupIntentId: String!
      $lm_data: String
      $price: Float
      $currency: String
      $stripeCustomerId: String
      $Name: String
      $Email: String
      $FirstName: String
      $LastName: String
      $PhoneNumber: String
    ) {
      createPaymentIntent3(
        projectId: $projectId
        productID: $productID
        priceID: $priceID
        setupIntentId: $setupIntentId
        lm_data: $lm_data
        price: $price
        currency: $currency
        stripeCustomerId: $stripeCustomerId
        Name: $Name
        Email: $Email
        FirstName: $FirstName
        LastName: $LastName
        PhoneNumber: $PhoneNumber
      ) {
        stripeClientId
      }
    }`,
};
