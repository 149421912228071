export const CREATE_PAYMENT_SETUP = {
  operationName: 'createPaymentSetup',
  query: `mutation createPaymentSetup(
      $email: String!
      $funnelId: String!
    ) {
      createPaymentSetup(
        email: $email
        funnelId: $funnelId
      ) {
        id
        client_secret
      }
    }`,
};
