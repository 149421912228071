import React from 'react';
import { Block } from './Block';
import styled from 'styled-components';
import StoreController from '../../StoreController';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { rxProjectId, rxProducts, rxStripeKey } from '../../rx/rxState';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import BlockWrapper from './BlockWrapper/BlockWrapper';

export const BlockStripeStyle = styled.div`
  padding: 10px;
  border: 1px solid #dadada;
  background-color: white;
  pointer-events: auto;
`;

// const callRewardfull = (customerEmail) => {
//   if (!customerEmail) {
//     console.error('REWARDFULL - no customer Email');
//     return;
//   }
//   try {
//     window.rewardful('convert', { email: customerEmail });
//   } catch (e) {
//     console.error('REWARDFULL', e);
//   }
// };

export class BlockStripeProxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputState: 'null', // 'null' means empty, 'invalid' - means empty or invalid (email format for example), 'good' - means good
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
    this.props.callback({
      verify: () => this.verify(),
      createPaymentIntent: (data, callback) =>
        this.handlePayment(data, callback),
      savePaymentMethodAndSubscribe: (data, callback) =>
        this.savePaymentMethodAndSubscribe(data, callback),
    });
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.styleText !== this.props.styleText) {
    this.props.reference.current.style.cssText = this.props.styleText();
    // }
  }

  savePaymentMethodAndSubscribe(data, callback) {
    let customerEmail;
    console.log('savePaymentMethodAndSubscribe');
    var thiz = this; //TODO: fix this weird workaround. see code inside .then block

    const cardElement = this.props.elements.getElement(CardElement);
    this.props.stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        // billing_details: {
        //     name: cardholderName.value,
        // }
      })
      .then((result) => {
        if (result.error) {
          console.log('ERROR:', result.error);
          callback(false, result.error.message);
          this.setState({ errorMessage: result.error.message });
        } else {
          console.log('Created payment method: ' + result.paymentMethod.id);
          const payload = {
            paymentMethod: result.paymentMethod.id,
            projectId: rxProjectId.getValue(),
            ...data,
          };
          if (payload['First Name']) {
            payload.FirstName = payload['First Name'];
            delete payload['First Name'];
          }
          if (payload['Last Name']) {
            payload.LastName = payload['Last Name'];
            delete payload['Last Name'];
          }
          if (payload.Email) {
            customerEmail = payload.Email;
          }
          graphQlCall({
            queryTemplateObject: QUERIES.AF_SUBSCRIBE,
            values: {
              ...payload,
              lm_data: window.lm_data,
            },
          }).then((data) => {
            if (data.error) {
              callback(false, data.error);
              this.setState({ errorMessage: data.error });
              return;
            } else {
              callback(true, null);
              thiz.setState({ errorMessage: '' });
              // callRewardfull(customerEmail);
            }
          });
        }
      });
  }

  confirmCardPayment(thiz, client_secret, callback) {
    const cardElement = this.props.elements.getElement(CardElement);
    this.props.stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
        setup_future_usage: 'off_session',
      })
      .then(function (result) {
        if (result.error) {
          callback(false, result.error.message);
          thiz.setState({ errorMessage: result.error.message });
          console.error('Purchase Error', result.error.message);
        } else {
          callback(true, null);
          thiz.setState({ errorMessage: '' });
        }
      });
  }

  confirmCardSetup(thiz, client_secret, callback) {
    const cardElement = this.props.elements.getElement(CardElement);
    this.props.stripe
      .confirmCardSetup(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          callback(false, result.error.message);
          thiz.setState({ errorMessage: result.error.message });
          console.error('Card Setup Error: ', result.error.message);
        } else {
          callback(true, null);
          thiz.setState({ errorMessage: '' });
        }
      });
  }

  async createPaymentSetup(email) {
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PAYMENT_SETUP,
      values: { email, funnelId: rxProjectId.getValue() },
    });
    const { id, client_secret } = response;
    return {
      id,
      client_secret,
    };
  }

  async createNewPaymentIntent(payload) {
    const result = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PAYMENT_INTENT_3,
      values: { ...payload, lm_data: window.lm_data },
    });
    if (result.stripeClientId) {
      const customerKey = `${payload.projectId}|customerId`;
      localStorage.setItem(customerKey, result.stripeClientId);
      return true;
    }
    return false;
  }

  async handlePayment(data, callback) {
    let customerEmail = '';
    this.setState({ errorMessage: '' });
    if (data.Email) {
      customerEmail = data.Email;
    }

    try {
      const { id, client_secret } = await this.createPaymentSetup(
        customerEmail
      );

      const cardElement = this.props.elements.getElement(CardElement);
      const verifyResult = await this.props.stripe.confirmCardSetup(
        client_secret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (!verifyResult || verifyResult.error) {
        console.error('Card not verified');
        return;
      }

      const payload = {
        projectId: rxProjectId.getValue(),
        setupIntentId: id,
        ...data,
      };
      if (payload['First Name']) {
        payload.FirstName = payload['First Name'];
        delete payload['First Name'];
      }
      if (payload['Last Name']) {
        payload.LastName = payload['Last Name'];
        delete payload['Last Name'];
      }
      if (payload.Email) {
        customerEmail = payload.Email;
      }
      if (payload.priceID) {
        payload.priceID = String(payload.priceID);
      }

      const intentResult = await this.createNewPaymentIntent(payload);
      if (!intentResult) {
        callback(false, 'create payment intent error');
        return;
      }
      callback(true, null);
      return true;
    } catch (error) {
      callback(false, error);
    }
  }

  createPaymentIntent(data, callback) {
    console.log('create payment intent with data:', data);
    let customerEmail;
    this.setState({ errorMessage: '' });
    const payload = {
      projectId: rxProjectId.getValue(),
      ...data,
    };
    if (payload['First Name']) {
      payload.FirstName = payload['First Name'];
      delete payload['First Name'];
    }
    if (payload['Last Name']) {
      payload.LastName = payload['Last Name'];
      delete payload['Last Name'];
    }
    if (payload.Email) {
      customerEmail = payload.Email;
    }
    if (payload.priceID) {
      payload.priceID = String(payload.priceID);
    }

    graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PAYMENT_INTENT_2,
      values: { ...payload, lm_data: window.lm_data },
    })
      .then((data) => {
        // console.log('data:',data);
        if (data.error) {
          callback(false, data.error);
          this.setState({ errorMessage: data.error });
          return;
        }
        // var thiz = this; //TODO: fix this weird workaround. see code inside .then block
        // console.log('data:', data);
        if (data.intent) {
          const intent = JSON.parse(data.intent);
          if (intent.customer) {
            localStorage.setItem('stripeCustomerId', intent.customer);
          }

          const client_secret = intent.client_secret;
          if (intent.object == 'setup_intent') {
            this.confirmCardSetup(this, client_secret, callback);
          } else if (intent.object == 'payment_intent') {
            this.confirmCardPayment(this, client_secret, callback);
          }
        } else {
          this.confirmCardPayment(this, data.client_secret, callback);
        }
      })
      .catch((error) => {
        callback(false, error);
        this.setState({ errorMessage: error });
        console.log('error', error);
      });

    return true;
  }

  verify() {
    const cardElement = this.props.elements.getElement(CardElement);

    console.log('cardElement', cardElement);

    if (!cardElement._complete) {
      return false;
    }
    return true;
  }

  render() {
    let content;
    if (StoreController.instance().liveMode) {
      //LIVE Mode
      content = (
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '18px',
                color: '#424770',
                '::placeholder': {
                  color: '#939292',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      );
    } else {
      //Dummy Preview
      content = (
        <div style={{ height: '30px', background: '#99a5ff', display: 'flex' }}>
          <div style={{ margin: 'auto' }}>STRIPE FORM PLACEHOLDER</div>
        </div>
      );
    }

    return (
      <BlockWrapper
        id={this.props.id}
        key={this.props.id}
        reference={this.props.reference}
        isDragging={this.props.isDragging}
        className={this.props.className}
        errors={this.props.errors}
      >
        {this.props.children}

        <BlockStripeStyle>{content}</BlockStripeStyle>

        <div style={{ color: '#ff5172', height: '30px' }}>
          {this.state.errorMessage}
        </div>
      </BlockWrapper>
    );
  }
}

export class BlockStripe extends Block {
  constructor(props) {
    super(props);

    this.type = 'Stripe';
    this.amount = 0;
    this.userProduct = undefined;

    const attributesArray = [
      {
        id: 'product',
        displayName: 'Product',
        value: '',
        visible: true,
        type: 'Dropdown',
        options: [],
        required: true,
        errorMessage: 'Set product',
      },
      {
        id: 'price',
        displayName: 'Price',
        value: '',
        type: 'Dropdown',
        visible: false,
        options: [],
        required: false,
        errorMessage: 'Set price',
      },
    ];
    attributesArray.forEach((attr) => this.addAttribute(attr));

    rxProducts.subscribe({
      next: (v) => this.updateProducts(v),
    });

    this.updateProducts(rxProducts.value);

    this.callback = null;

    rxStripeKey.subscribe((stripeKey) => {
      if (!stripeKey) return;
      this.stripePromise = loadStripe(stripeKey);
    });
  }

  pack() {
    let data = super.pack();
    data['amount'] = this.amount;
    if (this.userProduct !== undefined) {
      data['userProduct'] = this.userProduct;
    }
    return data;
  }

  unpack(data) {
    super.unpack(data);
    this.amount = data['amount'];
    this.userProduct = data['userProduct'];
    if (
      this.userProduct !== undefined &&
      !this.price.value &&
      !this.product.value
    ) {
      this.price.required = true;
      this.price.visible = true;

      this.product.required = true;
      this.product.visible = true;

      this.product.value = `${this.userProduct.id}`;

      if (this.userProduct.id && this.userProduct.id.indexOf('user') > -1) {
        const parts = this.userProduct.id.split('-');
        const id = parts[1];
        this.price.value = id;
      } else if (
        this.userProduct.name &&
        this.userProduct.price &&
        this.userProduct.currency
      ) {
        const priceValue = parseFloat(this.userProduct.price);
        const productValue = `book-${this.userProduct.name}`;

        this.product.options.push({
          label: this.userProduct.name,
          value: productValue,
          type: 'BOOK',
        });

        const productsValue = rxProducts.value;
        productsValue.push({
          id: productValue,
          name: this.userProduct.name,
          type: 'FUNNEL',
          prices: [
            {
              id: priceValue,
              unit_amount: this.userProduct.price * 100,
              currency: this.userProduct.currency,
            },
          ],
        });
        rxProducts.next(productsValue);

        this.product.value = productValue;

        this.price.options.push({
          label: this.userProduct.price + this.userProduct.currency,
          value: priceValue,
        });
        this.price.value = priceValue;
      }
    }
  }

  updateProducts(products) {
    let productOptions = [];
    for (let product of products) {
      let id;
      if (product.id) {
        id = product.id;
      } else {
        id = product.name;
      }
      productOptions.push({
        label: product.name,
        value: id,
        type: product.type,
      });
    }
    this.product.options = productOptions;
  }

  callbackHandler(callback) {
    this.callback = callback;
  }

  createPaymentIntent(data, callback) {
    let dict = {};
    for (const d of data) {
      dict[d.type] = d.value;
    }
    let currentProduct = null;
    for (const prod of rxProducts.getValue()) {
      if (prod.name === this.product.value) {
        currentProduct = prod;
      }
    }

    if (this.product.value.indexOf('book') > -1) {
      dict.price = parseFloat(this.userProduct.price);
      dict.currency = this.userProduct.currency;
    }

    if (currentProduct && currentProduct.isSevenDayTrial) {
      return this.callback.savePaymentMethodAndSubscribe(
        {
          ...dict,
          productID: this.product.value,
          priceID: String(this.price.value),
        },
        callback
      );
    } else {
      return this.callback.createPaymentIntent(
        {
          ...dict,
          productID: this.product.value,
          priceID: String(this.price.value),
        },
        callback
      );
    }
  }

  update() {
    super.update();
    let priceOptions = [];
    for (const product of rxProducts.value) {
      let id;
      if (product.id) {
        id = product.id;
      } else {
        id = product.name;
      }
      if (id === this.product.value) {
        if (!product.prices) continue;

        if (product.type === 'USER' || product.type === 'FUNNEL') {
          this.price.value = product.prices[0].id;
          this.amount = product.prices[0].unit_amount / 100;
          this.price.visible = false;
        } else {
          for (const price of product.prices) {
            priceOptions.push({
              label: price.unit_amount / 100 + ' ' + price.currency,
              value: price.id,
            });

            if (this.price.value === price.id) {
              this.amount = price.unit_amount / 100;
            }
          }
          this.price.visible = true;
          this.price.required = true;
        }
        break;
      }
    }

    this.price.options = priceOptions;
  }

  onAttributeChanged(attr) {
    if (attr === 'product') {
      this.price.value = '';
      this.amount = 0;
    }
  }

  verifyInput() {
    return this.callback.verify();
  }

  renderView() {
    const errors = { requiredAttributes: 0, errorMessages: [] };
    this.attributes.forEach((attr) => {
      if (attr.required) {
        errors.requiredAttributes += 1;
        if (!attr.value) errors.errorMessages.push(attr.errorMessage);
      }
    });

    return (
      <Elements stripe={this.stripePromise ?? null} key={this.id}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <>
              {/* { this.isDragging ? this.getPlaceholder() : null} */}
              <BlockStripeProxy
                stripe={stripe}
                isDragging={this.isDragging}
                elements={elements}
                id={this.id}
                key={this.id}
                view={this.view}
                reference={this.ref}
                ref={this.proxyRef}
                styleText={this.style}
                callback={(callbacks) => this.callbackHandler(callbacks)}
                className={this.className}
                product={this.product}
                errors={this.isSelected ? undefined : errors}
                missingKey={!this.stripePromise}
              />
            </>
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}
