import { rxManipulatorInUse } from 'rx/rxState';

const kEdgeToEdgeMode = 0;
const kCenterHandler = 0;
// const kLeftHandler = 1;
// const kRightHandler = 2;
// const kLeftUpHandler = 3;
// const kRightUpHandler = 4;
// const kRightDownHandler = 5;
// const kLeftDownHandler = 6;
const kLeftEdgeHandler = 7;
const kRightEdgeHandler = 8;
const kTopEdgeHandler = 9;
const kBottomEdgeHandler = 10;

export const kUpHandler = 11;
export const kDownHandler = 12;
export const kSectionReorderHandler = 13;
export const kBlockReorderHandler = 14;

export const kDraggingThreshold = 20;
export const kReroll = 21;
export const kRerollNewBlock = 22;
export const kDuplicateBlock = 23;
export const kSubmenu = 24;
export const kRemoveBlock = 25;
export const kRerollImage = 26;

const cursorsHover = {
  [kReroll]: 'pointer',
  [kRerollNewBlock]: 'pointer',
  [kBlockReorderHandler]: 'move',
  [kDuplicateBlock]: 'pointer',
  [kSubmenu]: 'pointer',
  [kRemoveBlock]: 'pointer',
  [kRerollImage]: 'pointer',
};

export default class Manipulator {
  constructor(view) {
    /**@type {Block[]} */
    this.blocks = [];
    this.isMousePressed = false;
    this.hasSubMenuOpened = false;
    this.view = view;

    this.mode = kEdgeToEdgeMode;
    this.boundingRect = { x: 0, y: 0, width: 0, height: 0 };
    this.handlerSize = 40;
    this.handlerWithBorderRadiusSize = 32;
    this.handlers = [];
    this.animations = {};
    this.animationStarted = false;
    this.anchorSize = 6;

    this.draggingThresholdCounter = kDraggingThreshold;

    this.blockSpanSizePixels = { start: 0, length: 0 }; //TODO: move out in to dedicated Manipulator class ( everything that is related to Span)

    this.blockReorderInfo = {
      rect: {},
      block: null,
      index: 0,
      direction: 0,
    }; //0 direct swap, -1 - place before, +1 - place behind
    this.clickOffset = { x: 0, y: 0 };

    const icons = [
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/move.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/moveHovered.svg`,
      //     key: kBlockReorderHandler
      //   },
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/reroll.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/rerollHovered.svg`,
      //     key: kReroll
      //   },
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/reroll.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/rerollHovered.svg`,
      //     key: kRerollNewBlock
      //   },
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/duplicate.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/duplicateHovered.svg`,
      //     key: kDuplicateBlock
      //   },
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/rerollImage.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/rerollImageHovered.svg`,
      //     key: kRerollImage
      //   },
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/submenu.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/submenuHovered.svg`,
      //     key: kSubmenu
      //   },
      //   {
      //     src: `${process.env.PUBLIC_URL}/assets/icons/remove.svg`,
      //     srcHovered: `${process.env.PUBLIC_URL}/assets/icons/removeHovered.svg`,
      //     key: kRemoveBlock
      //   },
      {
        src: `${process.env.PUBLIC_URL}/assets/icons/rightEdgeArrow.svg`,
        srcHovered: `${process.env.PUBLIC_URL}/assets/icons/rightEdgeArrowHovered.svg`,
        key: kRightEdgeHandler,
      },
      {
        src: `${process.env.PUBLIC_URL}/assets/icons/leftEdgeArrow.svg`,
        srcHovered: `${process.env.PUBLIC_URL}/assets/icons/leftEdgeArrowHovered.svg`,
        key: kLeftEdgeHandler,
      },
      {
        src: `${process.env.PUBLIC_URL}/assets/icons/topEdgeArrow.svg`,
        srcHovered: `${process.env.PUBLIC_URL}/assets/icons/topEdgeArrowHovered.svg`,
        key: kTopEdgeHandler,
      },
      {
        src: `${process.env.PUBLIC_URL}/assets/icons/bottomEdgeArrow.svg`,
        srcHovered: `${process.env.PUBLIC_URL}/assets/icons/bottomEdgeArrowHovered.svg`,
        key: kBottomEdgeHandler,
      },
    ];
    this.icons = {};
    for (const value of icons) {
      let icon = new Image();
      icon.src = value.src;
      let iconHovered = new Image();
      iconHovered.src = value.srcHovered;
      this.icons[value.key] = { icon: icon, iconHovered: iconHovered };
    }
  }

  setBlocks(blocks) {
    this.blocks = blocks;
  }
  onScroll(value) {
    // if(this.isMousePressed){
    //     this.clickOffset.y -= value/devicePixelRatio;
    // }
  }
  onMouseDownForBlock(block) {
    if (block.type != 'TextCover') {
      const size = this.view.getViewSize();
      const span = this.blocks[0].getGridSpan(size);
      const parent = this.blocks[0].parent;
      const { width, gap } = parent.columnWidthAndGap();

      if (parent.type == 'Row') {
        parent.renderGrid = true;
      }
      this.blockSpanSizePixels = {
        start: span.start * (width + gap),
        length: span.length * width + (span.length - 1) * gap,
      };
    }
  }

  onMouseDownForHandler(mousePos, handlerType) {
    this.clickOffset = {
      x: mousePos.x - this.boundingRect.x,
      y: mousePos.y - this.boundingRect.y,
    };

    if (
      handlerType == kLeftEdgeHandler ||
      handlerType == kRightEdgeHandler ||
      handlerType == kBlockReorderHandler
    ) {
      this.onMouseDownForBlock(this.blocks[0]);
    }
  }

  onMouseDown(mousePos, zIndex) {
    this.draggingThresholdCounter = kDraggingThreshold;
    if (this.blocks.length === 0) {
      return false;
    }
    this.isMousePressed = true;
    // debugger
    for (var i = this.handlers.length - 1; i >= 0; i--) {
      let handler = this.handlers[i];
      let rect = this.handlerBoundingRect(handler.type);
      // debugger
      if (
        rect.x < mousePos.x &&
        rect.y < mousePos.y &&
        rect.width + rect.x > mousePos.x &&
        rect.height + rect.y > mousePos.y
      ) {
        if (handler.zIndex < zIndex) {
          continue;
        }
        handler.clicked = true;
        rxManipulatorInUse.next(true);
        this.onMouseDownForHandler(mousePos, handler.type);

        return true;
      }
    }

    return false;
  }

  onMouseMoveForHandler(mousePos, movement, handlerType) {
    if (handlerType === kSectionReorderHandler) {
      let distance = Math.sqrt(
        movement.x * movement.x + movement.y * movement.y
      );
      this.draggingThresholdCounter -= distance;
      if (this.draggingThresholdCounter > 0) {
        return;
      }
      if (this.blocks[0] && !this.blocks[0].isDragging) {
        this.blocks[0].setDragging(true);
        this.view.setState({ isMouseDragging: true });
      }
      let pos = {
        x: mousePos.x * (1 / this.view.state.zoom),
        y: mousePos.y * (1 / this.view.state.zoom),
      };

      this.blocks[0].setOffset({
        x: pos.x - this.clickOffset.x * (1 / this.view.state.zoom),
        y:
          pos.y -
          this.clickOffset.y * (1 / this.view.state.zoom) +
          this.view.overlay.scrollTop,
      });

      this.view.sortSections({ x: pos.x, y: pos.y * this.view.state.zoom });
    } else if (handlerType == kLeftEdgeHandler) {
      if (this.blocks[0]) {
        this.resizeBlockSpan(movement.x, -movement.x);
      }
    } else if (handlerType == kRightEdgeHandler) {
      if (this.blocks[0]) {
        this.resizeBlockSpan(0, movement.x);
      }
    } else if (handlerType == kTopEdgeHandler) {
      if (this.blocks[0]) {
        let padding = this.blocks[0].padTop;
        if (padding === null) {
          padding = 10;
        }
        padding -= movement.y;
        if (padding < 0) {
          padding = 0;
        }
        this.blocks[0].padTop = padding;
        this.blocks[0].forceUpdate();
      }
    } else if (handlerType == kBottomEdgeHandler) {
      if (this.blocks[0]) {
        let padding = this.blocks[0].padBottom;
        if (padding === null) {
          padding = 10;
        }
        padding += movement.y;
        if (padding < 0) {
          padding = 0;
        }
        this.blocks[0].padBottom = padding;
        this.blocks[0].forceUpdate();
      }
    } else if (handlerType === kBlockReorderHandler) {
      if (this.blocks[0]) {
        if (this.blocks[0].type === 'TextCover') {
          // this.blocks[0].position.x += movement.x
          this.blocks[0].position = {
            ...this.blocks[0].position,
            y: this.blocks[0].position.y + movement.y,
          };
          this.blocks[0].forceUpdate();
        } else {
          const dragY = this.clickOffset.y + this.boundingRect.y - mousePos.y;
          if (
            dragY > 20 ||
            dragY < -this.boundingRect.height - 80 ||
            this.blocks[0].isDragging
          ) {
            if (this.blocks[0] && !this.blocks[0].isDragging) {
              this.blocks[0].setDragging(true);
            }

            let pos = {
              x: mousePos.x, //*(1/this.view.state.zoom),
              y: mousePos.y, //*(1/this.view.state.zoom)
            };
            this.blocks[0].setOffset({ x: pos.x, y: pos.y });
            this.updateBlockOrder(this.blocks[0], pos);
          } else {
            this.resizeBlockSpan(movement.x, 0);
          }
        }
      }
    }
  }

  onMouseMove(mousePos, movement) {
    //HOVER STATE
    for (let handler of this.handlers) {
      handler.hovered = false;
      for (var i = this.handlers.length - 1; i >= 0; i--) {
        let handler = this.handlers[i];
        let rect = this.handlerBoundingRect(handler.type);
        if (
          rect.x < mousePos.x &&
          rect.y < mousePos.y &&
          rect.width + rect.x > mousePos.x &&
          rect.height + rect.y > mousePos.y
        ) {
          handler.hovered = true;
          break;
        }
      }
    }
    //CLICK HANDLE
    for (let handler of this.handlers) {
      if (handler.clicked) {
        this.onMouseMoveForHandler(mousePos, movement, handler.type);
      }
    }
  }

  resizeBlockSpan(deltaStart, deltaLength) {
    let needsUpdate = false;

    this.blockSpanSizePixels.start += deltaStart;
    this.blockSpanSizePixels.length += deltaLength;
    const parent = this.blocks[0].parent;
    const { width, gap } = parent.columnWidthAndGap();
    // console.log('column width', width)
    const widthGap = width + gap;
    const span = {
      start: Math.floor(
        (this.blockSpanSizePixels.start + widthGap * 0.5) / widthGap
      ),
      length: Math.ceil(
        (this.blockSpanSizePixels.length - widthGap * 0.5 + gap) / widthGap
      ),
    };
    needsUpdate = this.blocks[0].setGridSpan(span);

    if (needsUpdate) {
      this.view.update();
    }
  }

  processChildrenBlocksFlat() {
    let rects = [];
    for (const block of this.view.blocks) {
      this.processChildren(rects, block.children, block);
    }
    return rects;
  }

  processChildren(rects, children, parent) {
    let index = 0;
    for (const child of children) {
      rects.push({
        parent: parent,
        localIndex: index,
        block: child,
      });
      index += 1;
      if (child.children.length > 0) {
        this.processChildren(rects, child.children, child);
      }
    }
  }

  updateBlockOrder(block, pos) {
    this.blockReorderInfo.block = null;
    const flatBlocks = this.processChildrenBlocksFlat().reverse();

    for (const b of flatBlocks) {
      if (this.view.rectContainPoint(b.block.worldRenderBRect, pos.x, pos.y)) {
        this.blockReorderInfo.block = b.block;
        this.blockReorderInfo.ownerBlock = block;
        this.blockReorderInfo.rect = b.block.worldRenderBRect;
        this.blockReorderInfo.index = b.localIndex; //TODO: remove it eventually, not used

        if (
          pos.y >
          b.block.worldRenderBRect.height * 0.5 + b.block.worldRenderBRect.y
        ) {
          this.blockReorderInfo.direction = 1;
        } else {
          this.blockReorderInfo.direction = -1;
        }

        if (b.block.type == 'Row') {
          this.blockReorderInfo.direction = 0;
        }

        break;
      }
    }
  }
  onMouseUpForHandler(handlerType) {
    if (
      handlerType === kSectionReorderHandler ||
      handlerType === kBlockReorderHandler
    ) {
      if (this.blocks[0] && this.blocks[0].isDragging) {
        this.blocks[0].setDragging(false);

        if (this.blocks[0].parent) {
          for (let block of this.blocks[0].parent.children) {
            if (block.type === 'Placeholder') {
              block.displayPlaceholder = false;
            }
          }
        }

        if (this.blockReorderInfo.block) {
          //reorder block
          let children = this.blockReorderInfo.block.parent.children;
          const parent = this.blockReorderInfo.block.parent;
          let destinationBlock = children[this.blockReorderInfo.index];
          if (destinationBlock.type == 'Placeholder') {
            let index = this.blockReorderInfo.index;
            children[index] = children.splice(
              this.blockReorderInfo.blockIndex,
              1,
              children[index]
            )[0];
          } else {
            const ownerBlock = this.blockReorderInfo.ownerBlock;
            ownerBlock.removeFromParent();
            const ownerBlockParent = ownerBlock.parent;

            if (this.blockReorderInfo.direction === 0) {
              this.blockReorderInfo.block.addChild(ownerBlock);
            } else {
              let to = parent.children.findIndex(
                (b) => b.id === this.blockReorderInfo.block.id
              );
              if (this.blockReorderInfo.direction > 0) {
                to += 1;
              }
              parent.addChild(ownerBlock, to);
            }
          }
          this.blockReorderInfo.block = null;
        }
        this.view.overlay.selectBlock(this.blocks[0]);
        this.view.setState({ isMouseDragging: false });
      }
    }
    if (
      handlerType === kLeftEdgeHandler ||
      handlerType === kRightEdgeHandler ||
      handlerType == kBlockReorderHandler
    ) {
      const parent = this.blocks[0].parent;
      if (parent.type == 'Row') {
        parent.renderGrid = false;
      }
    }
  }

  onMouseUp(e) {
    this.isMousePressed = false;
    for (let handler of this.handlers) {
      if (handler.clicked) {
        this.onMouseUpForHandler(handler.type);
        handler.clicked = false;
        rxManipulatorInUse.next(false);
      }
    }
  }

  grabReorderHandler() {
    this.isMousePressed = true;
    for (let handler of this.handlers) {
      if (handler.type === kSectionReorderHandler) {
        handler.clicked = true;
        break;
      }
    }
    // TODO: remove this super dirty hack that pushes Section all the way down so it will not be visible during "adding" event
    this.clickOffset = {
      x: 200 * this.view.state.zoom,
      y: (6000 - this.view.overlay.scrollTop) * this.view.state.zoom,
    };
  }

  selectionDidChanged() {
    if (this.hasSubMenuOpened) {
      this.hasSubMenuOpened = false;
    }
    this.update();
    if (this.blocks.length === 0) {
      return;
    }
    this.handlers = [];

    if (this.blocks[0].type === 'Section') {
      this.handlers = [
        {
          clicked: false,
          hovered: false,
          type: kSectionReorderHandler,
          zIndex: 0,
        },
      ];
    } else {
      if (this.blocks[0].isDraggable) {
        // this.handlers = [];
        // this.handlers = [
        //     {
        //         clicked: false,
        //         hovered: false,
        //         type:kBlockReorderHandler,
        //         zIndex: 1
        //     }
        // ];

        this.handlers.push({
          clicked: false,
          hovered: false,
          type: kCenterHandler,
          zIndex: 0,
        });

        // if (this.blocks[0].type == 'Text' && this.blocks[0].aiField) {
        //   this.handlers.push({
        //     clicked: false,
        //     hovered: false,
        //     type: kReroll,
        //     zIndex: 1
        //   })
        // }
        // if (this.blocks[0].type == 'Text' && !this.blocks[0].aiField) {
        //     this.handlers.push({
        //       clicked: false,
        //       hovered: false,
        //       type: kRerollNewBlock,
        //       zIndex: 1
        //     })
        //   }
      }
    }
    if (
      this.blocks[0].type !== 'Section' &&
      this.blocks[0].type != 'TextCover'
    ) {
      // this.handlers.push({
      //     clicked: false,
      //     hovered: false,
      //     type: kDuplicateBlock,
      //     zIndex: 1
      // })
      // this.handlers.push({
      //     clicked: false,
      //     hovered: false,
      //     type: kSubmenu,
      //     zIndex: 1
      // })

      this.handlers.push({
        clicked: false,
        hovered: false,
        type: kRightEdgeHandler,
        zIndex: 1,
      });

      this.handlers.push({
        clicked: false,
        hovered: false,
        type: kLeftEdgeHandler,
        zIndex: 1,
      });

      this.handlers.push({
        clicked: false,
        hovered: false,
        type: kBottomEdgeHandler,
        zIndex: 1,
      });

      this.handlers.push({
        clicked: false,
        hovered: false,
        type: kTopEdgeHandler,
        zIndex: 1,
      });

      // if (this.blocks[0].type === 'Image') {
      //     this.handlers.push({
      //         clicked: false,
      //         hovered: false,
      //         type: kRerollImage,
      //         zIndex: 1
      //     })
      // }
    }

    if (this.blocks[0].type !== 'Section' && this.hasSubMenuOpened) {
    }

    // moving kReroll and kRerollNewBlock to the end of the array so that they are rendered later than kDuplicateBlock, thereby overlapping it on the outside to hide the rounded edges that are added to make it look like a border-radius in case kDuplicateBlock is the first block
    for (let handler of this.handlers) {
      if (handler.type === kReroll || handler.type === kRerollNewBlock) {
        let index = this.handlers.indexOf(handler);
        this.handlers.splice(index, 1);
        this.handlers.push(handler);
      }
    }
  }

  activeHandler() {
    for (let handler of this.handlers) {
      if (handler.clicked) {
        return handler.type;
      }
    }
  }

  update() {
    if (this.blocks && this.blocks.length !== 0) {
      this.boundingRect = this.blocks[0].worldRenderBRect;
    }
  }
  handlerBoundingRect(handler) {
    let numberOfButtons = 4;

    if (!this.boundingRect && this.blocks && this.blocks.length !== 0) {
      this.boundingRect = this.blocks[0].worldRenderBRect || {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
    }
    let moveDublRerollAvailable = false;
    let onlyMoveDublAvailable = false;
    let onlyDublAvailable = false;
    let imageBlock = false;

    const allAvailableHandlers = this.handlers.map((handler) => handler.type);
    if (
      allAvailableHandlers.includes(kBlockReorderHandler) &&
      (allAvailableHandlers.includes(kRerollNewBlock) ||
        allAvailableHandlers.includes(kReroll)) &&
      allAvailableHandlers.includes(kDuplicateBlock)
    ) {
      moveDublRerollAvailable = true;
    }
    if (
      allAvailableHandlers.includes(kBlockReorderHandler) &&
      !allAvailableHandlers.includes(kRerollNewBlock) &&
      allAvailableHandlers.includes(kDuplicateBlock) &&
      !allAvailableHandlers.includes(kRerollImage)
    ) {
      onlyMoveDublAvailable = true;
    }
    if (
      !allAvailableHandlers.includes(kBlockReorderHandler) &&
      !allAvailableHandlers.includes(kRerollNewBlock) &&
      allAvailableHandlers.includes(kDuplicateBlock) &&
      !allAvailableHandlers.includes(kRerollImage)
    ) {
      onlyDublAvailable = true;
    }
    if (
      allAvailableHandlers.includes(kBlockReorderHandler) &&
      !allAvailableHandlers.includes(kRerollNewBlock) &&
      allAvailableHandlers.includes(kDuplicateBlock) &&
      allAvailableHandlers.includes(kRerollImage)
    ) {
      imageBlock = true;
    }
    if (moveDublRerollAvailable) {
      switch (handler) {
        case kCenterHandler:
        case kSectionReorderHandler:
          return {
            x: this.boundingRect.x,
            y: this.boundingRect.y,
            width: this.boundingRect.width,
            height: this.boundingRect.height,
          };

        case kBlockReorderHandler:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * 0.5 +
              this.handlerSize -
              this.handlerSize * 0.25,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kReroll:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * 0.5 -
              this.handlerSize -
              this.handlerSize * 0.25 +
              6,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize + 2,
            height: this.handlerSize,
          };
        case kRerollNewBlock:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * 0.5 -
              this.handlerSize -
              this.handlerSize * 0.25 +
              6,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize + 2,
            height: this.handlerSize,
          };
        case kDuplicateBlock:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * 0.5 -
              this.handlerSize * 0.25,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kSubmenu:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 +
              this.handlerSize * 1.5 -
              this.handlerSize * 0.25,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize,
            height: this.handlerSize,
          };
        case kRemoveBlock:
          return {
            x: this.boundingRect.x + this.boundingRect.width / 2.0 + 45 - 12,
            y: this.boundingRect.y - this.handlerSize + 30,
            width: 80,
            height: this.handlerSize,
          };
        default:
          break;
      }
    }
    if (onlyMoveDublAvailable) {
      switch (handler) {
        case kCenterHandler:
        case kSectionReorderHandler:
          return {
            x: this.boundingRect.x,
            y: this.boundingRect.y,
            width: this.boundingRect.width,
            height: this.boundingRect.height,
          };
        case kBlockReorderHandler:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 +
              this.handlerSize * 0.125 -
              this.handlerSize * 0.5,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kDuplicateBlock:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize +
              this.handlerSize * 0.125 -
              this.handlerSize * 0.5,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kSubmenu:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 +
              this.handlerSize * 0.125 +
              this.handlerSize * 0.5,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize,
            height: this.handlerSize,
          };
        case kRemoveBlock:
          return {
            x: this.boundingRect.x + this.boundingRect.width / 2.0 + 20 - 12,
            y: this.boundingRect.y - this.handlerSize + 30,
            width: 80,
            height: this.handlerSize,
          };

        default:
          break;
      }
    }
    if (onlyDublAvailable) {
      switch (handler) {
        case kCenterHandler:
        case kSectionReorderHandler:
          return {
            x: this.boundingRect.x,
            y: this.boundingRect.y,
            width: this.boundingRect.width,
            height: this.boundingRect.height,
          };

        case kDuplicateBlock:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kSubmenu:
          return {
            x: this.boundingRect.x + this.boundingRect.width / 2.0,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize,
            height: this.handlerSize,
          };
        case kRemoveBlock:
          return {
            x: this.boundingRect.x + this.boundingRect.width / 2.0 - 12,
            y: this.boundingRect.y - this.handlerSize + 30,
            width: 80,
            height: this.handlerSize,
          };

        default:
          break;
      }
    }
    if (imageBlock) {
      switch (handler) {
        case kCenterHandler:
        case kSectionReorderHandler:
          return {
            x: this.boundingRect.x,
            y: this.boundingRect.y,
            width: this.boundingRect.width,
            height: this.boundingRect.height,
          };

        case kBlockReorderHandler:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * 0.5 +
              this.handlerSize -
              this.handlerSize * 0.25,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kRerollImage:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * numberOfButtons * 0.5 +
              this.handlerSize * 0,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize + 2,
            height: this.handlerSize,
          };
        case kRerollNewBlock:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * numberOfButtons * 0.5 +
              this.handlerSize * 1,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize + 2,
            height: this.handlerSize,
          };
        case kDuplicateBlock:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 -
              this.handlerSize * numberOfButtons * 0.5 +
              this.handlerSize * 1,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerSize,
            height: this.handlerSize,
          };
        case kSubmenu:
          return {
            x:
              this.boundingRect.x +
              this.boundingRect.width / 2.0 +
              this.handlerSize * 1.5 -
              this.handlerSize * 0.25,
            y: this.boundingRect.y - 10 - this.handlerSize,
            width: this.handlerWithBorderRadiusSize,
            height: this.handlerSize,
          };
        case kRemoveBlock:
          return {
            x: this.boundingRect.x + this.boundingRect.width / 2.0 + 45 - 12,
            y: this.boundingRect.y - this.handlerSize + 30,
            width: 80,
            height: this.handlerSize,
          };
        default:
          break;
      }
    }
    switch (handler) {
      case kCenterHandler:
      case kSectionReorderHandler:
        return {
          x: this.boundingRect.x,
          y: this.boundingRect.y,
          width: this.boundingRect.width,
          height: this.boundingRect.height,
        };

      case kBlockReorderHandler:
        //   let y =  this.boundingRect.y - 5 - this.handlerSize
        //   if( y < 0){
        //       y = this.boundingRect.y + 5
        //   }
        return {
          x:
            this.boundingRect.x +
            this.boundingRect.width / 2 +
            this.handlerSize * 1 +
            3,
          y: this.boundingRect.y - this.handlerSize - 10,
          width: this.handlerSize,
          height: this.handlerSize,
        };
      case kReroll:
        return {
          x:
            this.boundingRect.x +
            this.boundingRect.width / 2 -
            this.handlerSize * 0.5 -
            this.handlerSize +
            this.handlerSize * 0.6,
          y: this.boundingRect.y - this.handlerSize - 10,
          width: this.handlerSize,
          height: this.handlerSize,
        };
      case kRerollNewBlock:
        return {
          x:
            this.boundingRect.x +
            this.boundingRect.width / 2 -
            this.handlerSize * 0.5 -
            this.handlerSize +
            this.handlerSize * 0.6,
          y: this.boundingRect.y - this.handlerSize - 10,
          width: this.handlerSize,
          height: this.handlerSize,
        };
      case kDuplicateBlock:
        return {
          x:
            this.boundingRect.x +
            this.boundingRect.width / 2.0 -
            this.handlerSize * 0.5 +
            this.handlerSize * 0.6,
          y: this.boundingRect.y - 10 - this.handlerSize,
          width: this.handlerSize,
          height: this.handlerSize,
        };
      case kRerollImage:
        return {
          x:
            this.boundingRect.x +
            this.boundingRect.width / 2 +
            this.handlerSize * 1.5,
          y: this.boundingRect.y - 10 - this.handlerSize,
          width: this.handlerSize,
          height: this.handlerSize,
        };
      // case kLeftHandler:
      //     return{
      //         x: this.boundingRect.x - this.handlerSize,
      //         y: this.boundingRect.y + this.boundingRect.height*0.5 - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }

      // case kRightHandler:
      //     return{
      //         x: this.boundingRect.x + this.boundingRect.width - this.handlerSize,
      //         y: this.boundingRect.y + this.boundingRect.height*0.5 - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }
      // case kUpHandler:
      //     return{
      //         x: this.boundingRect.x + this.boundingRect.width*0.5 - this.handlerSize,
      //         y: this.boundingRect.y - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }

      // case kDownHandler:
      //     return{
      //         x: this.boundingRect.x + this.boundingRect.width*0.5 - this.handlerSize,
      //         y: this.boundingRect.y + this.boundingRect.height - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }
      // case kLeftUpHandler:
      //     return{
      //         x: this.boundingRect.x - this.handlerSize,
      //         y: this.boundingRect.y - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }
      // case kRightUpHandler:
      //     return{
      //         x: this.boundingRect.x+this.boundingRect.width - this.handlerSize,
      //         y: this.boundingRect.y - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }
      // case kRightDownHandler:
      //     return{
      //         x: this.boundingRect.x+this.boundingRect.width - this.handlerSize,
      //         y: this.boundingRect.y + this.boundingRect.height - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }
      // case kLeftDownHandler:
      //     return{
      //         x: this.boundingRect.x - this.handlerSize,
      //         y: this.boundingRect.y + this.boundingRect.height - this.handlerSize,
      //         width: this.handlerSize*2,
      //         height: this.handlerSize*2
      //     }
      case kLeftEdgeHandler:
        return {
          x: this.boundingRect.x - this.anchorSize * 2,
          y:
            this.boundingRect.y -
            this.handlerSize * 0.5 +
            this.boundingRect.height * 0.5,
          width: this.anchorSize * 2,
          height: this.handlerSize,
        };
      case kRightEdgeHandler:
        return {
          x: this.boundingRect.x + this.boundingRect.width,
          y:
            this.boundingRect.y -
            this.handlerSize * 0.5 +
            this.boundingRect.height * 0.5,
          width: this.anchorSize * 2,
          height: this.handlerSize,
        };
      case kTopEdgeHandler:
        return {
          x:
            this.boundingRect.x -
            this.handlerSize +
            this.boundingRect.width * 0.5,
          y: this.boundingRect.y - this.anchorSize * 2,
          width: this.handlerSize * 2,
          height: this.anchorSize * 2,
        };
      case kBottomEdgeHandler:
        return {
          x:
            this.boundingRect.x -
            this.handlerSize +
            this.boundingRect.width * 0.5,
          y: this.boundingRect.y + this.boundingRect.height,
          width: this.handlerSize * 2,
          height: this.anchorSize * 2,
        };
      default:
        break;
    }
  }
  getHandler(handlerType) {
    for (const h of this.handlers) {
      if (h.type == handlerType) {
        return h;
      }
    }
    return null;
  }
  renderHandler(ctx, handler) {
    if (
      handler.type === kCenterHandler ||
      handler.type === kSectionReorderHandler ||
      (handler.invisible && !handler.clicked && !handler.hovered)
    ) {
      return;
    }

    let rect = this.handlerBoundingRect(handler.type);
    if (!handler.hideFill) {
      if (handler.clicked) {
        ctx.fillStyle = '#3E3E3E';
      } else if (handler.hovered) {
        ctx.fillStyle = '#3E3E3E';
      } else {
        ctx.save();
        ctx.fillStyle = 'black';
      }
    } else {
      ctx.fillStyle = 'transparent';
      if (handler.hovered) {
        ctx.fillStyle = 'rgb(41,44,48, 0.2)';
      }
    }
    if (
      handler.type == kLeftEdgeHandler ||
      handler.type == kRightEdgeHandler ||
      handler.type == kBottomEdgeHandler ||
      handler.type == kTopEdgeHandler
    ) {
      let roundCorenrs;
      if (handler.type == kLeftEdgeHandler) {
        roundCorenrs = [6, 0, 0, 6];
      } else if (handler.type == kRightEdgeHandler) {
        roundCorenrs = [0, 6, 6, 0];
      } else if (handler.type == kBottomEdgeHandler) {
        roundCorenrs = [0, 0, 6, 6];
      } else if (handler.type == kTopEdgeHandler) {
        roundCorenrs = [6, 6, 0, 0];
      }
      ctx.beginPath();
      ctx.roundRect(rect.x, rect.y, rect.width, rect.height, roundCorenrs);
      ctx.fill();
    } else {
      ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
    }

    let icon = null;
    if (this.icons[handler.type]) {
      if (handler.hovered) {
        icon = this.icons[handler.type].iconHovered;
      } else {
        icon = this.icons[handler.type].icon;
      }
    }
    if (icon) {
      if (handler.type === kRerollNewBlock) {
        ctx.drawImage(icon, rect.x - 6, rect.y, 52, 40);
      } else if (handler.type === kReroll) {
        ctx.drawImage(icon, rect.x - 6, rect.y, 52, 40);
      } else if (handler.type === kSubmenu) {
        ctx.drawImage(icon, rect.x, rect.y, 52, 40);
      } else if (handler.type === kDuplicateBlock) {
        ctx.drawImage(icon, rect.x - 6, rect.y, 52, 40);
      } else if (handler.type === kRemoveBlock) {
        ctx.drawImage(icon, rect.x - 15, rect.y, 100, 40);
      } else if (
        handler.type === kLeftEdgeHandler ||
        handler.type == kRightEdgeHandler
      ) {
        ctx.drawImage(icon, rect.x, rect.y + 13, 12, 12);
      } else if (
        handler.type === kTopEdgeHandler ||
        handler.type == kBottomEdgeHandler
      ) {
        ctx.drawImage(icon, rect.x + 34, rect.y, 12, 12);
      } else if (handler.type === kRerollImage) {
        ctx.drawImage(icon, rect.x - 6, rect.y, 52, 40);
      } else {
        ctx.drawImage(icon, rect.x + 12, rect.y + 12, 16, 16);
      }
    }
    ctx.restore();
  }

  renderOverlay(ctx) {
    if (this.blocks.length === 0) {
      return;
    }
    if (!this.blocks[0].isDragging) {
      let hovered = 'unset';
      for (let handler of this.handlers) {
        this.renderHandler(ctx, handler);
        if (handler.hovered && cursorsHover[handler.type]) {
          hovered = cursorsHover[handler.type];
        }
      }
      if (ctx.canvas.parentElement) {
        ctx.canvas.parentElement.style.cursor = hovered;
      }

      ctx.canvas.style.cursor = hovered;
    }

    if (this.blockReorderInfo.block) {
      ctx.strokeStyle = '#1983fc';
      ctx.fillStyle = 'rgb(240,240,240, 0.2)';
      ctx.lineWidth = 2;
      let rect = this.blockReorderInfo.rect;

      const positionMarkerHeight = 5;

      ctx.beginPath();

      if (this.blockReorderInfo.direction == 0) {
        ctx.shadowColor = 'transparent';
        ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
      } else {
        ctx.fillStyle = '#4957D8';

        var height = 0;
        if (this.blockReorderInfo.direction > 0) {
          height = rect.height;
        }

        ctx.roundRect(
          rect.x,
          rect.y + height - positionMarkerHeight,
          rect.width,
          positionMarkerHeight * 2,
          [20]
        );

        ctx.fill();
      }
    }
  }
}
