import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { getToken } from 'utils/api';

interface ProtectedRouteProps extends RouteProps {
  path: string;
  child: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  child,
  path,
  ...rest
}) => {
  useEffect(() => {
    if (!getToken()) {
      window.open('/console/login', '_self');
      return;
    }
  }, []);

  return (
    <Route path={path} {...rest}>
      {child}
    </Route>
  );
};

export default ProtectedRoute;
