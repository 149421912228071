import styles from './BookCoverPreview.module.scss';
import { useEffect, useState } from 'react';
import BookCoverLayer from './BookCoverLayer';
import clsx from 'clsx';

const bookCoverPreviewTemplates = {
  bk3: [
    {
      type: 'image',
      blend: 'normal',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk3-background.png',
    },
    {
      type: 'cover',
      blend: 'normal',
      geometry: [267, 29, 784, 17, 190, 831, 695, 931],
    },
    {
      type: 'image',
      blend: 'multiply',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk3-shadows.png',
    },
  ],
  bk4: [
    {
      type: 'image',
      blend: 'normal',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk4-background.png',
    },
    {
      type: 'cover',
      blend: 'normal',
      geometry: [205, 41, 790, 41, 205, 902, 790, 902],
    },
    {
      type: 'image',
      blend: 'multiply',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk4-shadows.png',
    },
  ],
  bk5: [
    {
      type: 'image',
      blend: 'normal',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk5-background.png',
    },
    {
      type: 'cover',
      blend: 'normal',
      geometry: [506, 146, 968, 135, 441, 855, 888, 943],
    },
    {
      type: 'image',
      blend: 'normal',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk5-mid.png',
    },
    {
      type: 'cover',
      blend: 'normal',
      geometry: [1, 211, 416, 16, 219, 887, 665, 790],
    },
    {
      type: 'image',
      blend: 'multiply',
      url: 'https://cdn.autofunnel.ai/cover-preview/bk5-shadows.png',
    },
  ],
};

interface ILayer {
  type: string;
  blend: string;
  url?: string;
  geometry?: number[];
}

interface IProps {
  image: string;
  template: 'bk3' | 'bk4' | 'bk5';
}

const BookCoverPreview = (props: IProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, [props.template]);

  const layerFactory = (layer: ILayer) => {
    if (layer.type === 'image') {
      return (
        <img
          className={clsx(styles.imageLayer, {
            [styles.multuplyBlend]: layer.blend === 'multiply',
          })}
          crossOrigin="anonymous"
          src={layer.url}
        ></img>
      );
    } else if (layer.type === 'cover') {
      return <BookCoverLayer image={props.image} geometry={layer.geometry} />;
    }
    return null;
  };

  return (
    <div className={clsx(styles.root, { [styles.hidden]: loading })}>
      {bookCoverPreviewTemplates[props.template].map((layer: any) =>
        layerFactory(layer)
      )}
    </div>
  );
};

export default BookCoverPreview;
